import React from 'react';
import { AppBar, Toolbar, Typography, Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const Header: React.FC = () => {
  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component={Link} to="/" style={{ textDecoration: 'none', color: 'white' }}>
          Vanguard
        </Typography>
        <Box ml={2}>
          <Button color="inherit" component={Link} to="/category/sports">
            Sports
          </Button>
          <Button color="inherit" component={Link} to="/category/entertainment">
            Entertainment
          </Button>
          <Button color="inherit" component={Link} to="/category/technology">
            Technology
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;