import React from 'react';
import { Box, Typography } from '@mui/material';
import PostTile from '../components/PostTile';

interface CategoryPageProps {
  category: string;
  posts: Array<{ title: string; image: string; category: string }>;
}

const CategoryPage: React.FC<CategoryPageProps> = ({ category, posts }) => {
  return (
    <Box>
      <Typography variant="h3" gutterBottom>{category}</Typography>
      {posts.map((post, index) => (
        <PostTile key={index} title={post.title} image={post.image} category={post.category} />
      ))}
    </Box>
  );
};

export default CategoryPage;