import React from 'react';
import { Box, Typography } from '@mui/material';

interface PostTileProps {
  title: string;
  image: string;
  category: string;
}

const PostTile: React.FC<PostTileProps> = ({ title, image, category }) => {
  return (
    <Box>
      <img src={image} alt={title} style={{ width: '100%', height: 'auto' }} />
      <Typography variant="h6" gutterBottom>{title}</Typography>
      <Typography variant="subtitle2" color="textSecondary">{category}</Typography>
    </Box>
  );
};

export default PostTile;