import React from 'react';
import Header from '../components/Header';
import { Typography, Box, Container } from '@mui/material';
import Footer from '../components/Footer';

const PostPage: React.FC = () => {
  return (
    <div>
      <Header />
      <Container>
        <Box mt={4}>
          <img src="/path/to/your/image.jpg" alt="Main Post" style={{ width: '100%' }} />
          <Typography variant="h6" color="textSecondary">
            Published on 20 October 2024
          </Typography>
          <Typography variant="h4" gutterBottom>
            The Main Title of the Post
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            The Subtitle of the Post
          </Typography>
          <Typography variant="body1">
            {/* Main text of the post here */}
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed viverra risus nec tortor fermentum, ac vehicula ligula malesuada.
          </Typography>
        </Box>
      </Container>
      <Footer />
    </div>
  );
};

export default PostPage;