import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';
import { Box, Button } from '@mui/material';
import axios from 'axios';

interface RichTextEditorProps {
  initialValue: string;
  onChange: (content: string) => void;
  postId?: string;
}

const modules = {
  toolbar: [
    [{ 'font': [] }],
    [{ 'size': ['small', false, 'large', 'huge'] }], 

    ['bold', 'italic', 'underline', 'strike'],

    [{ 'color': [] }, { 'background': [] }],   

    [{ 'script': 'sub'}, { 'script': 'super' }],   

    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'header': 1 }, { 'header': 2 }],   
    ['blockquote', 'code-block'],

    [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],  
    [{ 'indent': '-1'}, { 'indent': '+1' }],           

    [{ 'direction': 'rtl' }],  
    [{ 'align': [] }],               

    ['link', 'image', 'video', 'formula'],

    ['clean']
  ],
};

const formats = [
  'font', 
  'size',
  'bold', 'italic', 'underline', 'strike',
  'color', 'background',
  'script', 
  'header', 
  'blockquote', 'code',
  'list', 'indent',
  'direction', 'align',
  'link', 'image', 'video', 'formula',
];

const RichTextEditor: React.FC<RichTextEditorProps> = ({
  initialValue = "",
  onChange,
  postId,
}) => {
  // State to manage the editor's value
  const [editorValue, setEditorValue] = useState<string>(initialValue);

  useEffect(() => {
    setEditorValue(initialValue);
  }, [initialValue]);

  // Handle saving the content
  const handleSave = async () => {
    if (!postId) return;

    try {
      await axios.put(`/api/posts/${postId}`, { content: editorValue });
      alert('Post updated successfully');
    } catch (error) {
      console.error('Error updating post:', error);
      alert('Failed to update post');
    }
  };

  // Handle publishing the content
  const handlePublish = async () => {
    if (!postId) return;

    try {
      await axios.post(`/api/posts/publish/${postId}`);
      alert('Post published successfully');
    } catch (error) {
      console.error('Error publishing post:', error);
      alert('Failed to publish post');
    }
  };

  return (
    <Box>
      <ReactQuill
        theme="snow"
        value={editorValue}
        onChange={(value) => {
          setEditorValue(value);
          onChange(value);
        }}
        modules={modules}
        formats={formats}
        placeholder="Enter some text..."
      />
      <Button
        variant="contained"
        color="secondary"
        style={{ marginTop: '16px' }}
        onClick={handleSave}
      >
        Save Changes
      </Button>
      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: '16px' }}
        onClick={handlePublish}
      >
        Publish
      </Button>
    </Box>
  );
};

export default RichTextEditor;
