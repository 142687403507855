import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';

const AdminSignupRequest: React.FC = () => {
  const [email, setEmail] = useState('');

  const handleRequestSignup = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/admin/requestSignup`, { email });
      toast('Signup request submitted. Await approval.');
    } catch (error) {
      toast('Failed to submit signup request.');
    }
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>Request Signup Access</Typography>
      <TextField
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Button variant="contained" color="primary" onClick={handleRequestSignup} style={{ marginTop: '16px' }}>
        Request Access
      </Button>
    </Box>
  );
};

export default AdminSignupRequest;
