import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Homepage from './pages/Homepage';
import PostPage from './pages/PostPage';
import CategoryPage from './pages/CategoryPage';
import AdminLogin from './admin/AdminLogin';
import AdminRequestSignup from './admin/AdminSignupRequest';
import AdminSignup from './admin/AdminSignup';
import PostReview from './admin/PostReview';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ProtectedRoute from './components/ProtectedRoute';
import { AuthProvider } from './contexts/AuthContext';

const App: React.FC = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/post/:id" element={<PostPage />} />
          <Route path="/category/:category" element={<CategoryPage category="" posts={[]} />} />
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route path="/admin/request-signup" element={<AdminRequestSignup />} />
          <Route path="/admin/signup" element={<AdminSignup />} />
          <Route
            path="/admin/postreview"
            element={
              <ProtectedRoute>
                <PostReview />
              </ProtectedRoute>
            }
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
