import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography, Button, Dialog, DialogTitle, DialogContent, TextField, Chip } from '@mui/material';
import api from '../services/api';
import { Post } from '../types';
import { toast } from 'react-toastify';
import RichTextEditor from '../components/RichTextEditor';

import 'react-quill-new/dist/quill.snow.css';

const PostReview: React.FC = () => {
  const [posts, setPosts] = useState<Post[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedPost, setSelectedPost] = useState<Post | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [tags, setTags] = useState<string[]>([]);
  const [newTag, setNewTag] = useState<string>('');

  const fetchAllPosts = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get<Post[]>(`/posts/all?timestamp=${new Date().getTime()}`);
      console.log('Full response:', response); 
      const data = response.data;
      console.log ('fetched posts:', data)
      setPosts(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error('Error fetching posts:', error);
      toast.error('Failed to load posts');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchAllPosts();
  }, [fetchAllPosts]);

  const handleSelectPost = (post: Post) => {
    setSelectedPost(post);
    setTags(post.tags || []);
    setIsModalOpen(true);
  };

  const handleSavePost = async () => {
    if (!selectedPost) return;

    try {
      await api.put(`/posts/${selectedPost._id}`, {
        ...selectedPost,
        tags,
        isDraft: true,
      });
      toast.success('Post saved as a draft!');
      setIsModalOpen(false);
      fetchAllPosts(); 
    } catch (error) {
      console.error('Error saving post:', error);
      toast.error('Failed to save post.');
    }
  };

  const handlePublishPost = async () => {
    if (!selectedPost) return;

    try {
      await api.put(`/posts/${selectedPost._id}`, {
        ...selectedPost,
        tags,
        isDraft: false,
      });
      toast.success('Post published successfully!');
      setIsModalOpen(false);
      fetchAllPosts(); 
    } catch (error) {
      console.error('Error publishing post:', error);
      toast.error('Failed to publish post.');
    }
  };

  const handleDeletePost = async (postId: string) => {
    try {
      await api.delete(`/posts/${postId}`);
      toast.success('Post deleted successfully!');
      fetchAllPosts(); 
    } catch (error) {
      console.error('Error deleting post:', error);
      toast.error('Failed to delete post.');
    }
  };

  const handleAddTag = () => {
    if (newTag && !tags.includes(newTag)) {
      setTags([...tags, newTag]);
      setNewTag('');
    }
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box>
      <Typography variant="h5">Pending Post Review</Typography>
      {Array.isArray(posts) && posts.length > 1 ? (
        posts.map((post) => (
          <Box key={post._id} my={2} p={2} border="1px solid #ccc">
            <Typography variant="h6">{post.title}</Typography>
            <Typography variant="body2">
              Status: {post.isDraft ? 'Draft' : 'Published'}
            </Typography>
            <Button onClick={() => handleSelectPost(post)}>Review Post</Button>
            <Button onClick={() => handleDeletePost(post._id)} color="secondary">
              Delete Post
            </Button>
          </Box>
        ))
      ) : Array.isArray(posts) && posts.length === 1 ? (
        <Box key={posts[0]._id} my={2} p={2} border="1px solid #ccc">
          <Typography variant="h6">{posts[0].title}</Typography>
          <Typography variant="body2">
            Status: {posts[0].isDraft ? 'Draft' : 'Published'}
          </Typography>
          <Button onClick={() => handleSelectPost(posts[0])}>Review Post</Button>
          <Button onClick={() => handleDeletePost(posts[0]._id)} color="secondary">
            Delete Post
          </Button>
        </Box>
      ) : (
        <Typography>No posts available for review.</Typography>
      )}

      {selectedPost && (
        <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)} maxWidth="md" fullWidth>
          <DialogTitle>Editing Post: {selectedPost.title}</DialogTitle>
          <DialogContent>
            <Box mt={2}>
              <TextField
                fullWidth
                label="Title"
                value={selectedPost.title}
                onChange={(e) => setSelectedPost({ ...selectedPost, title: e.target.value })}
              />
              <TextField
                fullWidth
                label="Category"
                value={selectedPost.category}
                onChange={(e) => setSelectedPost({ ...selectedPost, category: e.target.value })}
                style={{ marginTop: '16px' }}
              />
              <TextField
                fullWidth
                label="Author"
                value={selectedPost.author}
                onChange={(e) => setSelectedPost({ ...selectedPost, author: e.target.value })}
                style={{ marginTop: '16px' }}
              />
              <Typography variant="body1" style={{ marginTop: '16px' }}>Tags:</Typography>
              <Box display="flex" alignItems="center">
                {tags.map((tag, index) => (
                  <Chip key={index} label={tag} onDelete={() => setTags(tags.filter((t) => t !== tag))} />
                ))}
                <TextField
                  value={newTag}
                  onChange={(e) => setNewTag(e.target.value)}
                  label="New Tag"
                  style={{ marginLeft: '16px' }}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      handleAddTag();
                    }
                  }}
                />
                <Button onClick={handleAddTag}>Add Tag</Button>
              </Box>
              <Typography variant="body1" style={{ marginTop: '16px' }}>Content:</Typography>
              <RichTextEditor
                initialValue={selectedPost.content}
                onChange={(content) => setSelectedPost({ ...selectedPost, content })}
                postId={selectedPost._id}
              />
            </Box>
            <Box display="flex" justifyContent="flex-end" mt={4}>
              <Button onClick={handleSavePost} variant="contained" color="secondary" style={{ marginRight: '16px' }}>
                Save Changes
              </Button>
              <Button onClick={handlePublishPost} variant="contained" color="primary">
                Publish Post
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
};

export default PostReview;
