import React from 'react';
import { Box, Typography } from '@mui/material';
import Slider from 'react-slick';
import PostTile from './PostTile';

interface TrendingSliderProps {
  trendingPosts: Array<{ title: string; image: string; category: string }>;
}

const TrendingSlider: React.FC<TrendingSliderProps> = ({ trendingPosts }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>Trending Stories</Typography>
      <Slider {...settings}>
        {trendingPosts.map((post, index) => (
          <PostTile key={index} title={post.title} image={post.image} category={post.category} />
        ))}
      </Slider>
    </Box>
  );
};

export default TrendingSlider;