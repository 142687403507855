import React from 'react';
import Header from '../components/Header';
import FeaturedPost from '../components/FeaturedPost';
import TrendingSlider from '../components/TrendingSlider';
import Footer from '../components/Footer';

const Homepage: React.FC = () => {
  return (
    <div>
      <Header />
      <FeaturedPost title={''} image={''} category={''} />
      <TrendingSlider trendingPosts={[]} />
      <Footer />
    </div>
  );
};

export default Homepage;