import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

const AdminSignup: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const token = query.get('token');

  const handleSignup = async () => {
    try {
      if (!token) throw new Error('Invalid or missing signup token.');
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/admin/signup`, { username, password, token });
      toast('Signup successful. You can now log in.');
      navigate('/admin/login');
    } catch (err: any) {
      setError(err.response?.data?.message || 'Signup failed. Please try again.');
    }
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>Complete Signup</Typography>
      {error && <Typography variant="body2" color="error" gutterBottom>{error}</Typography>}
      <TextField label="Username" value={username} onChange={(e) => setUsername(e.target.value)} fullWidth margin="normal" />
      <TextField label="Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} fullWidth margin="normal" />
      <Button variant="contained" color="primary" onClick={handleSignup} style={{ marginTop: '16px' }}>Signup</Button>
    </Box>
  );
};

export default AdminSignup;
