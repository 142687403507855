import React, { useState } from 'react';
import { Box, TextField, Button, Typography, CircularProgress } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AdminLogin: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async () => {
    setLoading(true);
    setError(null); 
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/login`,
        { username, password },
        { withCredentials: true }
      );
      if (response.status === 200) {
        setTimeout(() => {
          setLoading(false);
          navigate('/admin/postreview');
        }, 1000); 
      }
    } catch (err: any) {
      setError('Invalid credentials. Please try again.');
      setLoading(false);
    }
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>Admin Login</Typography>
      {error && <Typography color="error" gutterBottom>{error}</Typography>}
      <TextField
        label="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleLogin}
        style={{ marginTop: '16px' }}
        disabled={loading}
      >
        {loading ? <CircularProgress size={24} /> : 'Login'}
      </Button>
      <Button
        variant="text"
        color="secondary"
        onClick={() => navigate('/admin/request-signup')}
        style={{ marginTop: '16px' }}
      >
        Don't have an account? Request Access
      </Button>
    </Box>
  );
};

export default AdminLogin;
